/***********************************************************************
* Index Styles
***********************************************************************/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/***********************************************************************
* Toast Styles
***********************************************************************/

/*********************** ERROR ***********************/

.Toastify__toast--error {
  border: 1px solid #ffa1a1;
  border-radius: 20px !important;
  background: #fff4f4 !important;
}

/********************** SUCCESS **********************/

.Toastify__toast--success {
  border: 1px solid #65b97b !important;
  border-radius: 20px !important;
  background: #f2fbf6 !important;
}

/********************** WARNING **********************/

.Toastify__toast--warning {
  border: 1px solid #ffba79 !important;
  border-radius: 20px !important;
  background: #fff8f2 !important;
}

/*********************** INFO ************************/

.Toastify__toast--info {
  border: 1px solid #7ac1f8 !important;
  border-radius: 20px !important;
  background: #f6fcff !important;
}

/********************** LAYOUT ***********************/

.Toastify__toast-body {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.Toastify__toast-icon {
  margin-right: 20px !important;
}

.Toastify__toast>button>svg {
  display: none;
}